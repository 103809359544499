import React, { useEffect, useState } from "react";

const Game = () => {
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }

  const { height, width } = useWindowDimensions();

  return (
    <div>
      <iframe
        id="targetFrame"
        src="https://s3.ap-southeast-1.amazonaws.com/storage.mxrth.co/ptt/webgl/index.html"
        width={width}
        height={height + 2}
      ></iframe>
    </div>
  );
};

export default Game;
