import axios from 'axios';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import API from '../../../utils/api';

type FromValues = {
  email: string
}

export interface OptionInterface {
  readonly value: string;
  readonly label: string;
  readonly isFixed?: boolean;
}

const ResetPassword = () => {

  const { register, handleSubmit, watch, formState: { errors, isDirty, isValid } } = useForm<FromValues>();
  const navigate = useNavigate()

  const theme = {
    default: {
      label: "block uppercases tracking-wide text-gray-700 text-xs font-bold mb-2",
      input: "appearance-none block w-full bg-white text-[#6C86B4] shadow-sms border rounded-full py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-[#6C86B4]",
      pError: "text-red-500 text-xs italic text-rights",
      btn: "w-full shadow bg-[#6C86B4] focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded-full"
      // btn: "w-full shadow bg-[#6C86B4] hover:bg-[#A8A8A8] focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded-full"
    }
  }

  const onHandleSubmit = (data: any) => {

    toast.info('กำลังตรวจสอบข้อมูล...', {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    axios.post(API.FORGOT_PASSWORD, data)
      .then((res) => {
        if (res.data) {
          toast.success('ส่ง E-mail สำเร็จ!', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => {
            navigate(`/forgotPass/success`)
          }, 1500)
        } else {
          toast.error('E-mail ไม่ถูกต้อง!', {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }).catch((err) => {
        console.log(err);
        toast.error('E-mail ไม่ถูกต้อง!', {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })

  }

  return (
    <div>
      <form
        className="w-full lg:max-w-lg"
        onSubmit={handleSubmit((data) => {
          onHandleSubmit(data)
        })}
      >
        <div className='flex flex-wrap -mx-3 mb-6s'>
          <div className="w-full px-3 mb-6">
            <label className={theme.default.label} htmlFor="email">Email <span className="text-red-500">*</span></label>
            <input
              className={`${theme.default.input} ${errors.email ? ("border-red-500") : ("border-white")}`}
              id="email"
              type="text"
              placeholder="Enter your email"
              {...register("email", {
                required: {
                  value: true,
                  message: "โปรดระบุอีเมล"
                },
                pattern: {
                  value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i,
                  message: "โปรดระบุเป็นอีเมลเท่านั้น"
                },
              })}
            />
            <p className={theme.default.pError}>{errors.email?.message}</p>
          </div>
        </div>
        <button className={theme.default.btn}>
          Send to Email
        </button>
        <ToastContainer />
      </form>
    </div>
  )
}

export default ResetPassword
