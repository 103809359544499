import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Country, State, City } from 'country-state-city';
import Select from 'react-select';
import PdpaModal from "../../modal/PdpaModal";
import VrPinModal from "../../modal/VrPinModal";
import axios from "axios";
import API from "../../../utils/api";

type FormValues = {
  firstname: string
  lastname: string
  username: string
  password: string
  email: string
  phoneNumber: string
  gender: string
  country: string
  state: string
  receiveNews: string
  agree: string
}

export interface OptionInterface {
  readonly value: string;
  readonly label: string;
  readonly isFixed?: boolean;
}

const RegisterForm = () => {
  const { register, handleSubmit, watch, formState: { errors, isDirty, isValid } } = useForm<FormValues>();
  const [isOpenPdpaModal, setIsOpenPdpaModal] = useState<Boolean>(false)
  const [isOpenVrPinModal, setIsOpenVrPinModal] = useState<Boolean>(false)
  const [countries, setCountries] = useState(Country.getAllCountries())
  const [states, setStates] = useState(State.getStatesOfCountry("TH"))
  const [country, setCountry] = useState<any>(Country.getCountryByCode("TH"))
  const [state, setState] = useState<any>()
  const [data, setData] = useState<Object>({})
  const navigate = useNavigate()
  // const [countryOptions, setCountryOptions] = useState<OptionInterface[]>()

  const convertCountryOptions = () => {
    const optionList: OptionInterface[] = [];
    countries.map((item) => {
      if (item.isoCode === "TH") {
        // console.log(item.isoCode);
        optionList.push({
          value: item.isoCode,
          label: item.name,
          isFixed: true,
        })
      } else {
        optionList.push({
          value: item.isoCode,
          label: item.name,
        })
      }
    })

    return optionList;
  }

  useEffect(() => {
    convertCountryOptions()
    // console.log(Country.getAllCountries());
    // console.log(State.getStatesOfCountry("US"));
    // console.log(State.ge());
    // setCountryOptions(convertCountryOptions())
  }, []);

  const theme = {
    default: {
      label: "block uppercases tracking-wide text-gray-700 text-xs font-bold mb-2",
      input: "appearance-none block w-full bg-white text-[#6C86B4] shadow-sms border rounded-full py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-[#6C86B4]",
      pError: "text-red-500 text-xs italic text-rights",
      btn: "w-full shadow bg-[#6C86B4] focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded-full"
      // btn: "w-full shadow bg-[#6C86B4] hover:bg-[#A8A8A8] focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded-full"
    }
  }

  const onHandleSubmit = (data: any) => {
    // console.log(data);
    // setIsOpenVrPinModal(true)
    data = { ...data, ...{ country: country.name, state: state.name } }
    // console.log(data);
    setData(data)

    toast.info('กำลังตรวจสอบข้อมูล...', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    // POST Request Register
    axios.post(API.REGISTER, data)
      .then((res) => {

        console.log("Res", res);

        if (res) {
          // Alert Successfully
          toast.success('ลงทะเบียนสำเร็จ!', {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          setTimeout(() => {
            navigate("/register/success")
          }, 2000)

        } else {
          // Alert Errors
          toast.error('ลงทะเบียนไม่สำเร็จ!', {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }).catch((err) => {
        // Alert Errors
        console.log(err);

        const msg = err.response.data.error
        const msgSplit = msg.split(" ")

        const msgErr = msgSplit[7];

        if (msgErr == `"user_username_key"`) {
          toast.error('username ถูกใช้งานแล้ว', {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else if (msgErr == `"user_email_key"`) {
          toast.error('Email ถูกใช้งานแล้ว', {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error('เกิดข้อผิดพลาด ลงทะเบียนไม่สำเร็จ!', {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }

      })
  }

  return (
    <div>
      <form
        className="w-full lg:max-w-lg"
        onSubmit={handleSubmit((data) => {
          onHandleSubmit(data)
        })}
      >
        <div className="flex flex-wrap -mx-3 mb-6s">
          <div className="w-full md:w-1/2 px-3 mb-6">
            <label className={theme.default.label} htmlFor="firstname">First Name <span className="text-red-500">*</span></label>
            <input
              className={`${theme.default.input} ${errors.firstname ? ("border-red-500") : ("border-white")}`}
              id="firstname"
              type="text"
              placeholder="First Name"
              {...register("firstname", {
                required: {
                  value: true,
                  message: "โปรดระบุชื่อ"
                },
                pattern: {
                  value: /^[A-Za-zก-๏]+$/i,
                  message: "โปรดระบุชื่อเป็นตัวอักษรเท่านั้น"
                },
              })}
            />
            <p className={theme.default.pError}>{errors.firstname?.message}</p>
          </div>

          <div className="w-full md:w-1/2 px-3 mb-6">
            <label className={theme.default.label} htmlFor="lastname">Last Name <span className="text-red-500">*</span></label>
            <input
              className={`${theme.default.input} ${errors.lastname ? ("border-red-500") : ("border-white")}`}
              id="lastname"
              type="text"
              placeholder="Last Name"
              {...register("lastname", {
                required: {
                  value: true,
                  message: "โปรดระบุนามสกุล"
                },
                pattern: {
                  value: /^[A-Za-zก-๏]+$/i,
                  message: "โปรดระบุนามสกุลเป็นตัวอักษรเท่านั้น"
                },
              })}
            />
            <p className={theme.default.pError}>{errors.lastname?.message}</p>
          </div>

          <div className="w-full px-3 mb-6">
            <label className={theme.default.label} htmlFor="username">Username <span className="text-red-500">*</span></label>
            <input
              className={`${theme.default.input} ${errors.username ? ("border-red-500") : ("border-white")}`}
              id="firstName"
              type="text"
              placeholder="Enter your username"
              {...register("username", {
                required: {
                  value: true,
                  message: "โปรดระบุชื่อผู้ใช้งาน"
                },
                pattern: {
                  value: /^[A-Za-z0-9]+$/i,
                  message: "โปรดระบุชื่อผู้ใช้งานเป็นตัวอักษร a-z หรือตัวเลขเท่านั้น"
                },
              })}
            />
            <p className={theme.default.pError}>{errors.username?.message}</p>
          </div>

          <div className="w-full px-3 mb-6">
            <label className={theme.default.label} htmlFor="password">Password <span className="text-red-500">*</span></label>
            <input
              className={`${theme.default.input} ${errors.password ? ("border-red-500") : ("border-white")}`}
              id="password"
              type="password"
              placeholder="Enter your password"
              {...register("password", {
                required: {
                  value: true,
                  message: "โปรดระบุรหัสผ่าน"
                },
                minLength: {
                  value: 8,
                  message: "โปรดระบุรหัสผ่านอย่างน้อย 8 ตัว"
                },
                pattern: {
                  value: /^[A-Za-z0-9]+$/i,
                  message: "โปรดระบุรหัสผ่านเป็นตัวอักษร a-z หรือตัวเลขเท่านั้น"
                },
              })}
            />
            <p className={theme.default.pError}>{errors.password?.message}</p>
          </div>

          <div className="w-full px-3 mb-6">
            <label className={theme.default.label} htmlFor="email">Email <span className="text-red-500">*</span></label>
            <input
              className={`${theme.default.input} ${errors.email ? ("border-red-500") : ("border-white")}`}
              id="email"
              type="text"
              placeholder="Enter your email"
              {...register("email", {
                required: {
                  value: true,
                  message: "โปรดระบุอีเมล"
                },
                pattern: {
                  value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i,
                  message: "โปรดระบุเป็นอีเมลเท่านั้น"
                },
              })}
            />
            <p className={theme.default.pError}>{errors.email?.message}</p>
          </div>

          <div className="w-full px-3 mb-6">
            <label className={theme.default.label} htmlFor="phoneNumber">Phone Number <span className="text-red-500">*</span></label>
            <input
              className={`${theme.default.input} ${errors.phoneNumber ? ("border-red-500") : ("border-white")}`}
              id="phoneNumber"
              type="text"
              placeholder="Enter your phone number"
              {...register("phoneNumber", {
                required: {
                  value: true,
                  message: "โปรดระบุเบอร์มือถือ"
                },
                pattern: {
                  value: /^[0]{1}[6-9]{1}[0-9]{8}$/i,
                  message: "โปรดระบุเป็นเบอร์มือถือ"
                },
              })}
            />
            <p className={theme.default.pError}>{errors.phoneNumber?.message}</p>
          </div>

          <div className="w-full px-3 mb-6">
            <label className={theme.default.label} htmlFor="gender">Gender <span className="text-red-500">*</span></label>
            <div
              className="relative">
              <select
                className={`${theme.default.input} ${errors.gender ? ("border-red-500") : ("border-white")}`}
                id="gender"
                {...register("gender", {
                  required: {
                    value: true,
                    message: "โปรดเลือกเพศ"
                  }
                })}
              >
                <option value="" selected disabled>Select your gender</option>
                <option>Male</option>
                <option>Female</option>
                <option>Non-Binary</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
              </div>
            </div>
            <p className={theme.default.pError}>{errors.gender?.message}</p>
          </div>

          <div className="w-full px-3 mb-3">
            <label className={theme.default.label} htmlFor="country">Location <span className="text-red-500">*</span></label>
            {/* <Select
              options={countryOptions}
              defaultValue={{
                value: "TH",
                label: "Thailand",
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  color: "#6C86B4",
                  borderColor: "transparent",
                  backgroundColor: "white",
                  borderRadius: "30px",
                  padding: "3px"
                })
              }}
            /> */}
            <div
              className="relative">
              <select
                className={`${theme.default.input} ${errors.country ? ("border-red-500") : ("border-white")}`}
                id="country"
                {...register("country", {
                  required: {
                    value: true,
                    message: "โปรดเลือกประเทศ"
                  }
                })}
                onChange={(e) => {
                  setStates(State.getStatesOfCountry(e.target.value))
                  setCountry(Country.getCountryByCode(e.target.value))
                }}
              >
                <option value="" selected disabled>Select your country</option>
                {
                  countries.map((item) => (
                    <option key={item.isoCode} value={item.isoCode} selected={(item.isoCode === "TH") ? true : false}>{item.name}</option>
                  ))
                }
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
              </div>
            </div>
            <p className={theme.default.pError}>{errors.country?.message}</p>
          </div>

          <div className="w-full px-3 mb-3">
            <div
              className="relative">
              <select
                className={`${theme.default.input} ${errors.state ? ("border-red-500") : ("border-white")}`}
                id="state"
                {...register("state", {
                  required: {
                    value: true,
                    message: "โปรดเลือกจังหวัด"
                  }
                })}
                onChange={(e) => {
                  // console.log(State.getStateByCodeAndCountry(e.target.value, country.isoCode));
                  setState(State.getStateByCodeAndCountry(e.target.value, country.isoCode))
                }}
              >
                <option value="" selected disabled>Select your state</option>
                {
                  states.map((item) => (
                    <option key={item.isoCode} value={item.isoCode}>{item.name}</option>
                  ))
                }
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
              </div>
            </div>
            <p className={theme.default.pError}>{errors.state?.message}</p>
          </div>

          <div className="w-full px-3 mb-1">
            <div className="md:w-1/3"></div>
            <label className="md:w-2/3 block font-semibold">
              <input
                className="mr-2  cursor-pointer"
                type="checkbox"
                {...register("receiveNews")}
              />
              <span className="text-xs text-gray-700">
                I want to receive news from PTT
              </span>
            </label>
          </div>

          <div className="flex w-full px-3 mb-6">
            <label className="block font-bold">
              <input
                className="mr-2 cursor-pointer"
                type="checkbox"
                {...register("agree", {
                  required: {
                    value: true,
                    message: ""
                  },
                })}
              />
              <span className={`text-xs ${errors.agree ? ("text-red-500") : ("text-gray-700")} font-semibold mr-1`}>I agree to the terms of the </span>
            </label>
            <a href="https://pttpdpa.pttplc.com/Privacy/146156" target="_blank" rel="noreferrer" className={`text-xs font-bold my-auto ${errors.agree ? ("text-red-500") : ("text-[#6C86B4]")}`}>Privacy Policy.</a>
            {/* <button type="button" className={`text-xs font-bold ${errors.acceptPdpa ? ("text-red-500") : ("text-[#6C86B4]")}`} onClick={() => setIsOpenPdpaModal(true)}>Privacy Policy.</button> */}
          </div>


        </div>
        <button
          className={theme.default.btn}
          onClick={() => {
            // if (!isValid) {
            //   toast.error('โปรดระบุข้อมูลให้ครบถ้วน!', {
            //     position: "top-right",
            //     autoClose: 3000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "light",
            //   });
            // } else {
            //   setIsOpenPdpaModal(true)
            // }
          }}
        >
          Sign Up
        </button>
        <ToastContainer />
        <PdpaModal
          isOpen={isOpenPdpaModal}
          setIsOpen={setIsOpenPdpaModal}
          setIsOpenVrPinModal={setIsOpenVrPinModal}
        />
      </form>
      <VrPinModal
        isOpen={isOpenVrPinModal}
        setIsOpen={setIsOpenVrPinModal}
        data={data}
      />
    </div>
  )
}

export default RegisterForm;