const BASE_URL = process.env.REACT_APP_BASE_URL
const BYPASS_URL = process.env.REACT_APP_BYPASS_URL

const API = {
  REGISTER: `${BASE_URL}/users`,
  REGISTER_CONFIRM: `${BASE_URL}/users/info`,
  REGISTER_CHECK_PIN: `${BASE_URL}/users/check_otp`,
  REGISTER_USER_INTEGRATION: `${BASE_URL}/users/integration`,
  REGISTER_CHECK_SET_PIN: `${BASE_URL}/auth/users/check_set_pin`,
  REGISTER_SET_PIN: `${BASE_URL}/auth/users/pin`,
  LOGIN: `${BASE_URL}/users/login`,
  CHECK_PIN: `${BYPASS_URL}/auth/users/pin`,
  FORGOT_PASSWORD: `${BASE_URL}/users/forgot_password`,
  RESET_PASSWORD: `${BASE_URL}/users/reset_password`,
}

export default  API;