import axios from "axios"
import { useEffect, useState, } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import API from "../utils/api"

interface BoxDetailInterface {
  title: string
  content: string
}

const BoxDetail = ({ title, content }: BoxDetailInterface) => {
  return (
    <div className="grid grid-cols-12 text-left mb-4 max-w-md">
      <div className="col-span-5">{title}</div>
      <div className="col-span-1">:</div>
      <div className="col-span-6 text-black">{content}</div>
    </div>
  )
}
const CompleteProfileConfirmPage = () => {
  const [data, setData] = useState<any>("N/A")

  useEffect(() => {
    // setData(() => {
    //   return JSON.parse(
    //     localStorage.getItem("dataObj")!.toString()
    //   );
    // });
    setData(JSON.parse(localStorage.getItem("dataObj")!.toString()));
  }, [])

  return (
    <div className="grid gap-2 justify-center text-center mx-5 lg:mx-0 my-5 lg:my-10 px-10 lg:px-0 pt-14 pb-20 text-[#929292] bg-[#F8F8F8] rounded-2xl">
      <img src="/images/success-icon.png" alt="icon" className="mx-auto w-14" />
      <h1 className="text-[#6C86B4] text-2xl font-bold my-5">Profile Completed</h1>
      <p className="text-sm">เพิ่มข้อมูลผู้ใช้สมบูรณ์</p>
      <p className="text-sm mb-14">ท่านสามารถปิดหน้านี้ เพื่อใช้งาน PTT Art Gallery</p>
      <BoxDetail title="Your Name" content={`${data.firstName ?? "N/A"}`} />
      <BoxDetail title="Email" content={`${data.email ?? "N/A"} `} />
      <BoxDetail title="Phone" content={`${data.phoneNumber ?? "N/A"}`} />
      <BoxDetail title="Gender" content={`${data.gender ?? "N/A"}`} />
      <BoxDetail title="Location" content={`${data.country ?? "N/A"} ${data.state ?? "N/A"}`} />
      <img src="/images/PTTLogo.svg" alt="Logo" className="mx-auto w-36 mt-10" />
    </div>
  )
}

export default CompleteProfileConfirmPage;