import { Outlet } from "react-router-dom";

const RegisterLayout = () => {

  document.body.style.backgroundImage = "url('/images/bg-register.png')";
  document.body.style.backgroundAttachment = "fixed";

  return (
    <div className="container mx-auto lg:w-[1200px]">
      <Outlet />
    </div>
  )
}

export default RegisterLayout;