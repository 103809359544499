import React from 'react'
import ForgotPasswordFrom from "../components/form/ForgotPassword/ForgotPasswordFrom";

function ForgotPasswordPage() {
  return (
    <div className="grid gap-5 justify-center mx-3 px-5 my-10 py-14 bg-[#F8F8F8] rounded-2xl">
      <img src="/images/PTTLogo.svg" className="mx-auto w-1/2" />
      <h1 className="font-bold text-lg text-center mt-5 mb-5 text-gray-700">Forgot Password</h1>
      <ForgotPasswordFrom />
    </div>
  )
}

export default ForgotPasswordPage
