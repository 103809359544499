import React from 'react'
import ResetPasswordFrom from '../components/form/ForgotPassword/ResetPasswordFrom'

function ResetPasswordPage() {
  return (
    <div className="grid gap-5 justify-center mx-3 px-5 my-10 py-14 bg-[#F8F8F8] rounded-2xl">
      <img src="/images/PTTLogo.svg" alt="Logo" className="mx-auto w-1/2" />
      <h1 className="font-bold text-lg text-center mt-5 mb-5 text-gray-700">Reset Password</h1>
      <ResetPasswordFrom />
    </div>
  )
}

export default ResetPasswordPage