import React, { useEffect, useState } from 'react'
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { FaCrown } from 'react-icons/fa';
import { io } from "socket.io-client";

const socket = io("https://leaderboard.virtualspacebyptt.com/", { transports: ['websocket'] });

const LeaderboardFrom = () => {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [dataInfo, setDataInfo] = useState<{ id: number; score: number; username: string }[]>([]);

  useEffect(() => {
    socket.on('connect', () => {
      setIsConnected(true);
      console.log("connect");
      socket.emit('findAllLeaderboard');
    });

    socket.on('disconnect', () => {
      setIsConnected(false);
      console.log("disconnect");
    });

    socket.on('findAllLeaderboard', (data) => {
      setIsConnected(true);
      setDataInfo(data)

      console.log("data : ", data)
      console.log("findAllLeaderboard");
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
    };

  },[]);

  // สีทอง text-[#e8bd4b]
  // สีทองแดง text-[#CD7F32]
  // สีเทา text-[#9e9e9e]
  // <FaCrown className='text-[#e8bd4b]' size={22} />

  return (
    <>
      <div className='grid gap-5 grid-cols-3 justify-items-center'>
        <div className='font-bold'>Rank</div>
        <div className='font-bold'>Player</div>
        <div className='font-bold'>Total Score</div>
      </div>
      {dataInfo.length > 0
        ? dataInfo.map((item: any, index: any) => {
          return (
            <div className='grid gap-5 grid-cols-3 justify-items-center' key={index}>
              <div>
                {index+1}
              </div>
              <div>{item.username}<span className='text-slate-500'> #{item.id}</span></div>
              <div>{item.score}</div>
            </div>
          )
        }) : 
        <div>
          <h1 className='text-center'>downloading data...</h1>
        </div>
      }
    </>
  )
}

export default LeaderboardFrom