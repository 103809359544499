import CompleteProfileForm from "../components/form/completeProfile/CompleteProfile";

const CompleteProfilePage = () => {

  return (
    <div className="grid gap-5 justify-center mx-3 px-5 my-10 py-14 bg-[#F8F8F8] rounded-2xl">
      <img src="/images/PTTLogo.svg"alt="Logo" className="mx-auto w-1/2" />
      <h1 className="font-bold text-lg text-center mt-5 mb-5 text-gray-700">Complete Profile</h1>
      <CompleteProfileForm />
    </div>
  )
}

export default CompleteProfilePage;