
import axios from "axios"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import API from "../utils/api"

interface BoxDetailInterface {
  title: string
  content: string
}

const BoxDetail = ({ title, content } : BoxDetailInterface) => {
  return (
    <div className="grid grid-cols-12 text-left mb-4 max-w-md">
      <div className="col-span-5">{title}</div>
      <div className="col-span-1">:</div>
      <div className="col-span-6 text-black">{content}</div>
    </div>
  )
}

const RegisterConfirmPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState<any>({})

  useEffect(() => {
    axios.get(`${API.REGISTER_CONFIRM}/${searchParams.get("token")}`)
    .then((res) => {
      // console.log(res.data);
      setData(res.data)
    })
  }, [])

  return (
    <div className="grid gap-2 justify-center text-center mx-5 lg:mx-0 my-5 lg:my-10 px-10 lg:px-0 pt-14 pb-20 text-[#929292] bg-[#F8F8F8] rounded-2xl">
      <img src="/images/success-icon.png" alt="icon" className="mx-auto w-14" />
      <h1 className="text-[#6C86B4] text-2xl font-bold my-5">Register Success</h1>
      <p className="text-sm">การสมัครสมาชิกเสร็จสิ้น</p>
      <p className="text-sm mb-14">ท่านสามารถเข้าสู่ระบบได้ที่ PTT Art Gallery</p>
      <BoxDetail title="Your Name" content={data.fullName} />
      <BoxDetail title="Username" content={data.username} />
      <BoxDetail title="Password" content="***********" />
      <BoxDetail title="Email" content={data.email} />
      <BoxDetail title="Phone" content={data.phoneNumber} />
      <BoxDetail title="Gender" content={data.gender} />
      <BoxDetail title="Location" content={data.location} />
      <img src="/images/PTTLogo.svg" className="mx-auto w-36 mt-10" />
    </div>
  )
}

export default RegisterConfirmPage;