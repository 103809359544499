import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../../utils/api";

interface VrPinModalInterface {
  isOpen: Boolean
  setIsOpen: React.Dispatch<React.SetStateAction<Boolean>>
  data: Object
}

type FormValues = {
  pin1: string
  pin2: string
  pin3: string
  pin4: string
  pin5: string
  pin6: string
}

const VrPinModal = ({ isOpen, setIsOpen, data }: VrPinModalInterface) => {
  const { register, handleSubmit, watch, formState: { errors, isDirty, isValid } } = useForm<FormValues>();
  const navigate = useNavigate()

  const theme = {
    default: {
      input: "border-[1.5px] border-[#929292] text-[#929292] rounded-lg lg:rounded-2xl text-xl lg:text-3xl py-1 lg:py-3 w-full text-center"
    }
  }

  const onHandleSubmit = (dataObject: FormValues) => {
    // console.log("Old", data);
    const pin = `${dataObject.pin1}${dataObject.pin2}${dataObject.pin3}${dataObject.pin4}${dataObject.pin5}${dataObject.pin6}`;
    data = { ...data, ...{ pin: pin } };
    console.log(pin);
    // console.log("New", data);

    // POST Request Check PIN
    axios.post(API.REGISTER_CHECK_PIN, { pin: pin })
      .then((res) => {
        const status = res.data.status;
        if (status) {

          // POST Set Pin Register
          axios.post(API.REGISTER_SET_PIN, { pin: pin }, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem("access_token")
            }
          })
            .then((res) => {
              console.log("Res", res);
              if (res) {
                // Alert Successfully
                toast.success('สร้าง PIN สำเร็จ!', {
                  position: "top-right",
                  autoClose: 1500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                setTimeout(() => {
                  navigate("/register/success")
                  setIsOpen(false)
                }, 2000)
              }
            })
        } else {
          // Alert Errors
          toast.error('PIN นี้ถูกใช้งานแล้วโปรดลองใหม่อีกครั้ง!', {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
  }

  const onHandleChangeInput = (e: any, focusId: number) => {
    let nextId = focusId;
    let previousId = focusId;
    if (focusId >= 1 && focusId <= 6) {
      nextId = nextId + 1;
      previousId = previousId - 1;
    }

    if (e.target.value != "") {
      document.getElementById(`pin${nextId}`)?.focus();
    } else {
      document.getElementById(`pin${previousId}`)?.focus();
    }
  }

  return (
    <form className={`static ${!isOpen && "hidden"}`}
      onSubmit={handleSubmit((data) => {
        onHandleSubmit(data)
      })}
    >
      <div className="absolute top-0 left-0">
        <div className="flex w-full h-full fixed justify-center items-center">
          <div className="fixed w-full h-full bg-[#00000090] z-10"></div>
          <div className="w-[90%] lg:w-[28%] h-auto bg-[#F8F8F8] rounded-3xl z-[11] px-8 py-10">
            <h2 className="text-center text-2xl font-bold mb-2 text-[#424242]">Create PIN</h2>
            <div className="grid grid-cols-6 gap-3 my-7 lg:px-8">
              <input
                className={theme.default.input}
                type="text"
                id="pin1"
                maxLength={1}
                {...register("pin1", {
                  required: {
                    value: true,
                    message: "โปรดกรอกข้อมูลให้ครบถ้วน"
                  },
                  pattern: {
                    value: /[0-9]+$/i,
                    message: "โปรดระบุ PIN เป็นตัวเลข 0-9 เท่านั้น"
                  },
                })}
                onChange={(e) => { onHandleChangeInput(e, 1) }}
              />
              <input
                className={theme.default.input}
                type="text"
                id="pin2"
                maxLength={1}
                {...register("pin2", {
                  required: {
                    value: true,
                    message: "โปรดกรอกข้อมูลให้ครบถ้วน"
                  },
                  pattern: {
                    value: /[0-9]+$/i,
                    message: "โปรดระบุ PIN เป็นตัวเลข 0-9 เท่านั้น"
                  },
                })}
                onChange={(e) => { onHandleChangeInput(e, 2) }}
              />
              <input
                className={theme.default.input}
                type="text"
                id="pin3"
                maxLength={1}
                {...register("pin3", {
                  required: {
                    value: true,
                    message: "โปรดกรอกข้อมูลให้ครบถ้วน"
                  },
                  pattern: {
                    value: /[0-9]+$/i,
                    message: "โปรดระบุ PIN เป็นตัวเลข 0-9 เท่านั้น"
                  },
                })}
                onChange={(e) => { onHandleChangeInput(e, 3) }}
              />
              <input
                className={theme.default.input}
                type="text"
                id="pin4"
                maxLength={1}
                {...register("pin4", {
                  required: {
                    value: true,
                    message: "โปรดกรอกข้อมูลให้ครบถ้วน"
                  },
                  pattern: {
                    value: /[0-9]+$/i,
                    message: "โปรดระบุ PIN เป็นตัวเลข 0-9 เท่านั้น"
                  },
                })}
                onChange={(e) => { onHandleChangeInput(e, 4) }}
              />
              <input
                className={theme.default.input}
                type="text"
                id="pin5"
                maxLength={1}
                {...register("pin5", {
                  required: {
                    value: true,
                    message: "โปรดกรอกข้อมูลให้ครบถ้วน"
                  },
                  pattern: {
                    value: /[0-9]+$/i,
                    message: "โปรดระบุ PIN เป็นตัวเลข 0-9 เท่านั้น"
                  },
                })}
                onChange={(e) => { onHandleChangeInput(e, 5) }}
              />
              <input
                className={theme.default.input}
                type="text"
                id="pin6"
                maxLength={1}
                {...register("pin6", {
                  required: {
                    value: true,
                    message: "โปรดกรอกข้อมูลให้ครบถ้วน"
                  },
                  pattern: {
                    value: /[0-9]+$/i,
                    message: "โปรดระบุ PIN เป็นตัวเลข 0-9 เท่านั้น"
                  },
                })}
                onChange={(e) => { onHandleChangeInput(e, 6) }}
              />
            </div>
            <p className="text-center text-red-500 mb-3 px-8">
              {
                errors.pin1?.message ||
                errors.pin2?.message ||
                errors.pin3?.message ||
                errors.pin4?.message ||
                errors.pin5?.message ||
                errors.pin6?.message
              }
            </p>
            <p className="text-center text-[#929292] mb-7 px-10">สร้าง PIN รหัส 6 หลักสำหรับใช้ยืนยันตัวตนในการเข้าใช้งานผ่านแว่น VR</p>
            <div className="flex justify-center items-center gap-3">
              <button
                id="createPIN"
                type="submit"
                className={`w-full ${(isDirty) ? "bg-[#6C86B4] hover:bg-[#516d9e]" : "bg-[#B8B8B8]"} text-white font-bold px-10 py-2 rounded-full`}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default VrPinModal;