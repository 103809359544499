const RegisterSuccessPage = () => {
  return (
    <div className="grid gap-2 justify-center text-center my-5 mx-5 lg:mx-0 px-8 lg:px-0 lg:my-10 pt-14 pb-10 lg:pb-20 bg-[#F8F8F8] rounded-2xl">
      <img src="/images/PTTLogo.svg" alt="Logo" className="mx-auto w-1/2" />
      <h1 className="text-[#424242] text-2xl font-bold pb-2 pt-3">Confirmation Email Sent.</h1>
      <p className="text-[#929292] text-sm font-bold">Please check your email and confirm your email address.</p>
    </div>
  )
}

export default RegisterSuccessPage;