import axios from 'axios';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import API from '../../../utils/api';

type FormValues = {
  token: string
  password: string
  passwordConfirm: string
}

export interface OptionInterface {
  readonly value: string;
  readonly label: string;
  readonly isFixed?: boolean;
}

const ResetPasswordFrom = () => {

  const { register, handleSubmit, watch, formState: { errors, isDirty, isValid } } = useForm<FormValues>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [msgError, setMsgError] = useState("");
  const navigate = useNavigate();

  const theme = {
    default: {
      label: "block uppercases tracking-wide text-gray-700 text-xs font-bold mb-2",
      input: "appearance-none block w-full bg-white text-[#6C86B4] shadow-sms border rounded-full py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-[#6C86B4]",
      pError: "text-red-500 text-xs italic text-rights",
      btn: "w-full shadow bg-[#6C86B4] hover:bg-[#A8A8A8] focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded-full"
    }
  }

  const onHandleSubmit = (data: FormValues) => {

    if (data.password == data.passwordConfirm) {
      const dataPass = {
        token: searchParams.get("token"),
        password: data.password,
      }

      toast.info('กำลังตรวจสอบข้อมูล...', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      axios.post(API.RESET_PASSWORD, dataPass)
        .then((res) => {
          toast.success('เปลี่ยนรหัสผ่านสำเร็จ !', {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => {
            navigate(`/reset_password/success`)
          }, 1500)
        }).catch((err) => {
          console.log(err);
          toast.error('server error!', {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })
    } else {
      toast.error('รหัสผ่านไม่ตรงกัน!', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  return (
    <div>
      <form
        className="w-full lg:max-w-lg"
        onSubmit={handleSubmit((data) => {
          onHandleSubmit(data)
        })}
      >
        <div className="w-full px-3 mb-6">
          <label className={theme.default.label} htmlFor="password">Password <span className="text-red-500">*</span></label>
          <input
            className={`${theme.default.input} ${errors.password ? ("border-red-500") : ("border-white")}`}
            id="password"
            type="password"
            placeholder="Enter your password"
            {...register("password", {
              required: {
                value: true,
                message: "โปรดระบุรหัสผ่าน"
              },
              minLength: {
                value: 8,
                message: "โปรดระบุรหัสผ่านอย่างน้อย 8 ตัว"
              },
              pattern: {
                value: /^[A-Za-z0-9]+$/i,
                message: "โปรดระบุรหัสผ่านเป็นตัวอักษร a-z หรือตัวเลขเท่านั้น"
              },
            })}
          />
          <p className={theme.default.pError}>{errors.password?.message}</p>
        </div>
        <div className="w-full px-3 mb-6">
          <label className={theme.default.label} htmlFor="passwordConfirm">Confirm Password<span className="text-red-500">*</span></label>
          <input
            className={`${theme.default.input} ${errors.passwordConfirm ? ("border-red-500") : ("border-white")}`}
            id="passwordConfirm"
            type="password"
            placeholder="Enter your password"
            {...register("passwordConfirm", {
              required: {
                value: true,
                message: "โปรดระบุรหัสผ่าน"
              },
              minLength: {
                value: 8,
                message: "โปรดระบุรหัสผ่านอย่างน้อย 8 ตัว"
              },
              pattern: {
                value: /^[A-Za-z0-9]+$/i,
                message: "โปรดระบุรหัสผ่านเป็นตัวอักษร a-z หรือตัวเลขเท่านั้น"
              },
            })}
          />
          <p className={theme.default.pError}>{errors.password?.message}</p>
        </div>
        <button
          className={theme.default.btn}
        >
          Confirm
        </button>
        <ToastContainer />
      </form>
    </div>
  )
}

export default ResetPasswordFrom