import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import RegisterPage from './pages/RegisterPage';
import RegisterLayout from './layouts/RegisterLayout';
import RegisterSuccessPage from './pages/RegisterSuccessPage';
import RegisterConfirmPage from './pages/RegisterConfirmPage';
import LandingPage from './pages/LandingPage';
import LandingPageLayout from './layouts/LandingPageLayout';
import CompleteProfilePage from './pages/CompleteProfilePage';
import CompleteProfileConfirmPage from './pages/CompleteProfileConfirmPage';
import PinCreatePage from './pages/PinCreatePage';
import PinCreateSuccessPage from './pages/PinCreateSuccessPage';
import Game from './pages/Game';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ForgotPasswordSuccessPage from './pages/ForgotPasswordSuccessPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ResetPasswordSuccessPage from './pages/ResetPasswordSuccessPage';
import LeaderboardPage from './pages/LeaderboardPage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <Routes>
      {/* Landing Page */}
      <Route path="/" element={<LandingPageLayout />} >
        <Route index element={<LandingPage />} />
      </Route>

      {/* Register */}
      <Route path="/register" element={<RegisterLayout />}>
        <Route index element={<RegisterPage />} />
        <Route path="success" element={<RegisterSuccessPage />} />
        <Route path="confirm" element={<RegisterConfirmPage />} />
        {/* <Route index element={<RegisterPage />} /> */}
        {/* <Route path="teams" element={<Teams />}>
          <Route path=":teamId" element={<Team />} />
          <Route path="new" element={<NewTeamForm />} />
          <Route index element={<LeagueStandings />} />
        </Route> */}
      </Route>

      {/* Complete Profile */}
      <Route path="/completeProfile/:facebookID" element={<RegisterLayout />} >
        <Route index element={<CompleteProfilePage />} />
        <Route path="success" element={<CompleteProfileConfirmPage />} />
      </Route>

      {/* Create PIN */}
      {/* <Route path='/createPIN/:token' element={<RegisterLayout />} >
        <Route index element={<PinCreatePage />} />
        <Route path="success" element={<PinCreateSuccessPage />} />
      </Route> */}

      {/* forgot Password */}
      <Route path='/forgotPass' element={<RegisterLayout />} >
        <Route index element={<ForgotPasswordPage />} />
        <Route path="success" element={<ForgotPasswordSuccessPage />} />
      </Route>

      {/* Reset Password */}
      <Route path='/reset_password' element={<RegisterLayout />} >
        <Route index element={<ResetPasswordPage />} />
        <Route path="success" element={<ResetPasswordSuccessPage />} />
      </Route>

      {/* Game */}
      <Route path='/CNXgallery' element={<Game />} />

      {/* Leaderboard  */}
      <Route path='/Leaderboard' element={<RegisterLayout />} >
        <Route index element={<LeaderboardPage />}></Route>
      </Route>

    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
