const theme = {
  header: `grid grid-cols-1 
            gap-1 w-[76.76px] 
            pt-[30px] pl-[30px] 
            md:w-[126px]
            md:pt-[48px] md:pl-[48px]
            ` ,
  formatBody: `grid grid-cols-1 gap-2 lg:grid-cols-2
              mt-[56px] px-[20px] font-bold text-[50px]
              text-[#464646] md:mt-[63px] md:px-[100px] lg:px-[130px]
  ` ,
  buttonDefuit: `text-[#6C86B4] bg-white
                font-normal  rounded-full text-sm md:text-[24px] 
                px-10 py-2.5 md:px-14 md:py-4` ,
  humanerror: `w-[300px] md:w-[600px]  lg:w-[1920px]`
}


const LandingPage = () => {

  // Logo PTT
  // <img src='/images/landingpageV2/ptt-logo.svg' alt="logo" className="overflow-hidden w-[110px] h-[58px] md:w-[40%] md:h-[100%] lg:w-[189px] lg:h-[60px] xl:h-[100%] xl:w-[25%] 2xl:w-[60%]" />

  // PC Banner
  // <img src='/images/landingpageV2/main-title-01.png' alt="logo-desktop" className="overflow-hidden w-[90%] lg:w-[80%] xl:w-[100%] 2xl:w-[95%]" />

  // moblie Banner
  //  <img src='/images/landingpageV2/main-title-01.png' alt="logo-desktop" className="overflow-hidden w-[75%] md:w-[85%]" />

  // character
  // <img src='/images/landingpageV2/character-01.png' alt="logo-desktop" className="overflow-hidden w-[70%] md:w-[50%] xl:w-[65%] 2xl:w-[80%]" />

  // Button
  // <button type="button"
  //   className={`${theme.buttonDefuit}`}>
  //   <a href="/CNXgallery" className="overflow-hidden text-[#230D6E] text-base lg:text-lg xl:text-xl 2xl:text-2xl px-2">
  //     Start Now
  //   </a>
  // </button>

  return (
    <div className="relative overflow-hidden w-full h-screen">

      {/* Logo PTT */}
      <img
        src='/images/landingpageV4/ptt-logo.png'
        alt="logo"
        className="absolute top-[3%] lg:top-[6%] xl:top-[5.5%] left-[5%] lg:left-[15%] xl:left-[25%] w-[20%] lg:w-[15%] xl:w-[8%]"
      />

      {/* Banner */}
      <img
        src='/images/landingpageV4/main-title-01.png'
        alt="logo-desktop"
        className="absolute top-[30%] md:top-[40%] lg:top-[40%] xl:top-[40%] w-[80%] lg:w-[60%] xl:w-[45%] left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      />

      {/* Character */}
      <img
        src='/images/landingpageV4/character-01.png'
        alt="logo-desktop"
        className="absolute top-[26rem] md:top-[33rem] lg:top-[33rem] xl:top-[35rem] left-[10rem] md:left-[29rem] lg:left-[60%] xl:left-[59.5%] mx-auto"
      />

      {/* Button */}
      <button type="button"
        className={`${theme.buttonDefuit} absolute bottom-[2rem] xl:bottom-[1rem] left-1/2 transform -translate-x-1/2 -translate-y-1/2`}>
        <a href="/CNXgallery"
          className="text-[#5856d6] text-sm lg:text-lg xl:text-xl 2xl:text-2xl px-1">
          Start Now
        </a>
      </button>

    </div>

  )
}

export default LandingPage