import React from 'react'
import LeaderboardFrom from '../components/form/Leaderboard/LeaderboardFrom'

function LeaderboardPage() {
  return (
    <div className="grid gap-5 justify-center mx-3 px-5 my-10 py-14 bg-[#F8F8F8] rounded-2xl">
      {/* <img src="/images/PTTLogo.svg" alt="Logo" className="mx-auto w-1/2" /> */}
      <img src="/images/landingpageV3/icon.png" alt="Logo" className="mx-auto w-[70%] md:w-[50%] lg:w-[40%]" />
      <h1 className="font-bold text-lg text-center mt-5 mb-5 text-gray-700">LEADERBOARD</h1>
      <LeaderboardFrom />
    </div>
  )
}

export default LeaderboardPage